import StationCard from "./stationCard.js"
function MainContent(props) {
  let stations = props.stations.map((station, index) => {
    return (
      <li key={index}>
        <StationCard stationPlay={props.stationPlay} station={station} />
      </li>
     )
  })
  return (
    
    <main className="main-content">
      <ul className="vScroll">
      {stations}
      </ul>
    </main>
  );
}

export default MainContent;
