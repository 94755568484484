import './App.css';
import React from 'react';
import HeaderBar from './HeaderBar.js';
import MainContent from './MainContent.js';
import Player from './Player.js';

class App extends React.Component {
  state = {
    stations: [],
    currentStation: null,
    isPlaying: false,
  }
  constructor(props) {
    super(props);
    this.stationPlay = this.stationPlay.bind(this);
    this.playToggle = this.playToggle.bind(this);
  }
  // 
  componentDidMount() {
    console.log('did mount');
    // fetch the streams data
    
    fetch("streams.json").then(data=>{
      data.json().then(json=>{
        this.setState({stations: json});
      });
      
    });
    
  }
  
  render() {
    return (
      <div className="App">
        <HeaderBar isPlaying={this.state.isPlaying} currentStation={this.state.currentStation} playToggle={this.playToggle} />
        <MainContent stationPlay={this.stationPlay} stations={this.state.stations}/>
        <Player currentStation={this.state.currentStation} isPlaying={this.state.isPlaying}/>
      </div>
    );
  }
  
  stationPlay(station){
    // set the current statiion
    this.setState({currentStation: station});
    this.setState({isPlaying: true});
  }
  
  playToggle(){
    // what is the state of the player
    if (this.state.isPlaying) {
      this.setState({isPlaying:false});
      // pause the audio
    } else {
      // is there a current station?
      if (!this.state.currentStation) {
        // pick a random station
        var stations = this.state.stations;
        var rando = Math.floor(Math.random() * stations.length);
        this.setState({currentStation:stations[rando]});
      }
      this.setState({isPlaying:true});
    }
    
    // if a station is not 'current' pick one at random
  }
  
  playerState(pState){
    console.log(pState);
  }
}

export default App;
