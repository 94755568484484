import {Component} from "react";
class StationCard extends Component {

  render(){
    return (
      <div className='card'>
        <button className="stationButton" onClick={()=>this.props.stationPlay(this.props.station)}>
        <h1>{this.props.station.name} {this.props.station.genre && <span className='genre'>{this.props.station.genre}</span>}</h1>
        <span>{this.props.station.location}</span>
				
        </button>
      </div>
    );
  }
}
export default StationCard;
