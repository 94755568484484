import  {useEffect, createRef } from 'react';

function Player(props) {
  let source = null;
  let playerRef = createRef();
  useEffect(() => {
    source = (props.currentStation) ? props.currentStation.streamSrc : null;
    // add some event listeners
    //playerRef.current.addEventListener('play', (event)=>{console.log(event)});
    //playerRef.current.addEventListener('error', (event)=>{console.log(event)});
    //playerRef.current.addEventListener('readyState', (event)=>{console.log(event)});
    if (source){
      playerRef.current.src = source;
    }
    if (props.isPlaying){
      //reload the stream
      playerRef.current.load();
      // play
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
    
  });
  
  
  return (
       <audio id="audioPlayer"
        src={source}
        ref={playerRef}
        >
        
    </audio>
  );
}

export default Player;
